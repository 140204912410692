.hot-cold-container {
  display: flex;
  justify-content: center;
  font-size: 2vh;
}

.switch-toggle {
  float: left;
  background: #d1cec3;
  border-radius: 3.5vh;
}
.switch-toggle input {
  position: absolute;
  opacity: 0;
}
.switch-toggle input + label {
  padding: 0.75vh;
  float: left;
  color: #fdf6e3;
  cursor: pointer;
  border-radius: 3.5vh;
}
.switch-toggle input:checked + label#NORMAL {
  background: #2aa198;
}

.switch-toggle input:checked + label#WARM {
  background: #cb4b16;
}

.switch-toggle input:checked + label#COOL {
  background: #268bd2;
}