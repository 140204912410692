@font-face {
  font-family: "Nobel";
  font-weight: 900;
  src: local("Nobel"), url(./assets/fonts/nobel-regular.woff) format("woff");
}

#header {
  font-family: Nobel;
  font-weight: black;
  font-size: 6.8vh;
  transition: .3s ease-in-out;
}

/* TODO: media query for landscape orientation. */

@media all and (max-width: 750px) {
  #header {
    font-size: 4vh;
    transition: .3s ease-in-out;
  }
}