@font-face {
  font-family: "Nobel";
  font-weight: normal;
  src: local("Nobel"), url(./assets/fonts/nobel-regular.woff) format("woff");
}

.Forecast {
  font-family: Nobel;
  color: #002b36;
  margin: 0 15vw;
}

.city {
  text-align: center;
  font-size: 3.5vh;
  font-weight: 9000;
}

.date-display {
  text-align: center;
}

.weather-details {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 50%;
  justify-content: center;
  margin-right: 1vw;
}

.temperature {
  font-size: 4.3vh;
}

.weather {
  font-size: 2.2vh;
}

.wind-speed {
  font-size: 2.2vh;
}

.weather-icon {
  fill: #073642;
  width: 13vh;
  height: 100%;
}

.weather-container {
  display: flex;
  justify-content: center;
}

.city-date-weather-container {
  border-radius: 5vw;
  background: #fdf6e3;
  padding-bottom: 0.5vh;
}

div.city-list-container {
  text-align: center;
}

.city-list {
  display: inline-block;
  text-align: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.city-list:empty {
  display: none;
}

@media all and (max-width: 750px) {
  .Forecast {
    margin: 0;
    padding-right: 0px;
    transition: 0.3s ease-in-out;
  }

  .city-date-weather-container {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 0%;
    transition: 0.3s ease-in-out;
  }

  .weather-details {
    margin-right: 2vw;
  }
}
