@font-face {
  font-family: 'Nobel';
  font-weight: normal;
  src: local('Nobel'), url(./assets/fonts/nobel-regular.woff) format('woff');
}

@font-face {
  font-family: 'NobelBold';
  font-weight: bold;
  src: local('Nobel'), url(./assets/fonts/nobel-bold.woff) format('woff');
}

#animationOrder1 {
  --animation-order: 1;
}
#animationOrder2 {
  --animation-order: 2;
}
#animationOrder3 {
  --animation-order: 3;
}
#animationOrder4 {
  --animation-order: 4;
}
#animationOrder5 {
  --animation-order: 5;
}
#animationOrder6 {
  --animation-order: 6;
}
#animationOrder7 {
  --animation-order: 7;
}
#animationOrder8 {
  --animation-order: 8;
}

.flexbox-container{
    display: flex;
    flex-direction: row;
    /*background: green;*/
  }

  .myIcon{
    height: 100%;
    min-height: 100%;
    margin: 0;
  }

  .text-div{
    font-family: Nobel;
    flex: 1;
    text-align: left;
    min-height: 10vh;
    /*background: purple;*/
    color: #002b36;
    align-self: flex-start;
    font-size: 2.2vh;
    margin: 0 0 0 calc(10vh * 1.65);
  }

  .text-div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-div > .text-title{
      font-family: NobelBold;
      font-size: 3.7vh;
  }

  .list-item li{
    width: 100%;
    min-height: 10vh; /* Requires updating in Dresser.css and Hat.css*/
    /*background: red;*/
    color: black;
    margin: 1vw 0;
  }

  @media all and (max-width: 750px) {

    .text-div {
      font-size: 2.2vh;
    }

    .text-div > .text-title {
      font-size: 5.5vw;
    }
  }