li.city-container {
  text-align: left;
}

/* Clear button styling*/
button.city-button {
  color: inherit;
  background-color: #fdf6e3;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 2vh;
  margin: 0;
  padding: 0;
}
