.g1 {
  --animation-order: 1;
}
.g2 {
  --animation-order: 2;
}
.g3 {
  --animation-order: 3;
}
.g4 {
  --animation-order: 4;
}

.dresser-list {
  display: block;
  padding-left: 0;
  margin-top: 0;
  padding-top: 1%;
  transition: 0.3s ease-in-out;
}

.dresser-list li {
  display: block;
  width: 100%;
  min-height: 10vh; /* Requires updating in Garment.css and Hat.css*/
  color: black;
  animation-name: animateIn;
  animation-duration: 350ms;
  animation-delay: calc(var(--animation-order) * 100ms);
  animation-fill-mode: both;
  background: #fdf6e3;
  border-radius: 5vw;
}

.dresser-list li + li {
  margin-top: 1%;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }

  100% {
    opacity: 1;
  }
}

@media all and (max-width: 750px) {
  .dresser-list {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 2%;
  }
  .dresser-list li + li {
    margin-top: 2%;
    transition: 0.3s ease-in-out;
  }
}
