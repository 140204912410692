@font-face {
  font-family: 'Nobel';
  font-weight: normal;
  src: local('Nobel'), url(./assets/fonts/nobel-regular.woff) format('woff');
}

@font-face {
  font-family: 'NobelBold';
  font-weight: bold;
  src: local('Nobel'), url(./assets/fonts/nobel-bold.woff) format('woff');
}

body {
  margin: 0;
  font-family: Nobel,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #073642;
  color: #fdf6e3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#clothing-category-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: #073642;
}

#clothing-category-container > div {
  margin: 0 2vw 0 0; /* spacing in-between title and sport */
  background-color: #073642;
}

/* Used for footer */
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
}