.my-funky-container {
  display: flex;
  justify-content: center;
}

.city-input-form {
  display: flex;
  flex-direction: row;
  padding: 2px;
  min-height: 2.5vh;
}

.city-input {
  flex-grow: 2;
  border: none;
  background-color: #bebaaa;
  max-width: 30vw;
  border-radius: 2.5vh 0 0 2.5vh;
  color: #002b36;
  padding-left: 5px;
  font-size: 2vh;
}

.city-input:focus {
  /* remove the input focus blue box.*/
  outline: none;
}

.city-search-button {
  border: 1px solid #2aa198;
  background: #2aa198;
  color: #fdf6e3;
  max-width: 10vw;
  border-radius: 0 2.5vh 2.5vh 0;
  cursor: pointer;
  outline: none !important;
  font-size: 2vh;
}

.city-search-button:active {
  -webkit-box-shadow: inset 0px 0px 20px #72c4be;
  -moz-box-shadow: inset 0px 0px 20px #72c4be;
  box-shadow: inset 0px 0px 20px #72c4be;
  border: 1px solid #72c4be;
}

@media all and (max-width: 750px) {
  .city-input-form {
    min-height: 3vh;
  }

  .city-input {
    border-radius: 3.5vh 0 0 3.5vh;
    max-width: 60vw;
  }

  .city-search-button {
    border-radius: 0 3.5vh 3.5vh 0;
    max-width: 20vw;
  }
}
