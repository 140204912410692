@font-face {
  font-family: "Nobel";
  font-weight: 900;
  src: local("Nobel"), url(./assets/fonts/nobel-regular.woff) format("woff");
}

a {
  transition: .3s ease-in-out;
  text-decoration: none;
  font-family: Nobel;
  font-size: 6.8vh;
}

nav {
  font-family: Nobel;
}

ul.sport-list,
ul.dropdown {
  background: #073642;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sport {
  color: #fdf6e3;
  background: #073642;
  display: block;
  float: left;
  /*padding: 1rem;*/
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  min-width: 11rem;
  z-index: 1;
}

li a {
  color: #fdf6e3;
}

/*
li:hover{
  background: red;
  cursor: pointer;
}
*/

#running:hover {
  background-color: #cb4b16;
  cursor: pointer;
}

#cycling:hover {
  background-color: #cb4b16;
  cursor: pointer;
}

#hiking:hover {
  background-color: #cb4b16;
  cursor: pointer;
}

#skiing:hover {
  background-color: #cb4b16;
  cursor: pointer;
}

ul li ul {
  background: orange;
  visibility: hidden;
  opacity: 0;
  min-width: 11rem;
  position: absolute;
  transition: all 0.5s ease;
  /*margin-top: 1rem;*/
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

@media all and (max-width: 750px) {
  a {
    font-size: 4vh;
    transition: .3s ease-in-out;
  }
}