@font-face {
  font-family: "Ubuntu";
  font-weight: bold;
  src: local("Ubuntu"),
    url(./assets/fonts/ubuntu-bold-italic.woff) format("woff");
}

.footer-container {
  background-color: #586e75;
  color: #fdf6e3;
  height: 5vh;
  margin-top: auto;
}

.footer {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bitcoin-logo-icon {
  fill: #f90;
  width: 4vh;
  height: 4vh;
}

.bitcoin-logo-text {
  font-family: Ubuntu;
  font-size: 2.5vh;
  margin-right: 10px;
  text-decoration: none;
  color: #fdf6e3;
}

.bitcoin-logo-text:hover {
  color: #f90;
}

.bitcoin-receiving-address {
  margin-right: 10px;
  font-size: 1.5vh;
  text-decoration: none;
  color: #fdf6e3;
}

.bitcoin-receiving-address:hover {
  color: #f90;
}

.libreapay-badge > a {
  font-size: 1.5vh;
  margin: 0;
  margin-right: 10px;
}

.libreapay-badge > a img {
  width: auto;
  height: 4vh;
  vertical-align: middle;
}

/* Ensure donatio icons stay on same row on mobile devices*/
.donation-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.donation-message {
  margin-right: 10px;
}

.contact-link {
  font-size: 1em;
  margin-right: 10px;
  text-decoration: none;
  color: #fdf6e3;
}

.contact-link:hover{
  color: #00FFFF;
}

@media all and (max-width: 750px) {
  
  .footer-container {
    height: auto;
  }
  .footer {
    flex-direction: column;
  }

  .contact-link:hover{
    color: #e3fdf6;
  }

  .bitcoin-logo-text:hover {
    color: #fdf6e3;
  }
  
  .bitcoin-receiving-address:hover {
    color: #fdf6e3;
  }

}

